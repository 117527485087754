<template>
    
    <div>

        <v-app-bar height="150" class="hidden-sm-and-down" style="background: transparent;width:80%;margin:0 auto;display:block;" flat>

<img src="@/assets/logo.png" width="120"/>
<v-spacer></v-spacer>
<v-btn active-class="btns" 
rounded
class="dfds" v-for="(item, i) in menu" :key="i" text @click="irPagina(item.link)"
style="
text-transform: none;
color: black;
margin-right: 20px;
letter-spacing: 0;
font-size: 17px;
font-weight: 400;
"    
>
{{ item.name }}</v-btn>



<v-btn active-class="btns" class="dfd"  text @click="irPagina('/contactenos')"
style="
text-transform: none;
color: white;
background: #167619;
letter-spacing: 0;
font-size: 18px;
"    
>Contáctenos</v-btn>

</v-app-bar>

        <v-app-bar height="100" class="hidden-md-and-up" 
        style="background: transparent;width:90%;margin:0 auto;display:block;" flat>

        <img src="@/assets/logo.png" width="100"/>
        <v-spacer></v-spacer>

        <v-btn 
        @click="irPagina('/contactenos')"
        active-class="btns" class="dfd"  text
        style="
        text-transform: none;
        color: white;
        background: #167619;
        letter-spacing: 0;
        font-size: 18px;
        "    
        >Contáctenos</v-btn>

        <v-btn text icon large @click="nav = !nav" style="font-weight: 900;">
        <i class="fas fa-bars"></i>
        </v-btn>

        </v-app-bar>

        <v-navigation-drawer v-model="nav" app fixed>
            <v-img src="@/assets/logo.png" style="margin:20px auto;display:block;"></v-img>
            <v-list shaped dense>
                <v-list-item-group active-class="jshs"             
                    color="#167616"
                >
                    <v-list-item
                    v-for="(item, i) in menu"
                    @click="irPagina(item.link)"
                    :key="i"
                    >
                    <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                </v-list>
        </v-navigation-drawer>

    </div>

</template>

<style>
.dfd:hover {
    box-shadow: 0px 0px 0px 5px rgba(22, 118, 22, 0.4);
}
.jshs {
    border-left: solid 4px #167616;
}
</style>

<script>
export default {
    data(){
        return {
            nav:false,
            selectedItem: 1,
            menu:[
            {name : 'Inicio', link: '/'},
            {name : 'Nosotros', link: '/nuestros-conceptos'},
            {name : 'Asesores', link: '/nuestro-equipo'}
            ]
        }
    },
    methods:{
        irPagina(e){
            window.open(e, "_self")
        }
    }
}
</script>